import store from '../store';
import * as storage from '../common/storage';
import { getClientBase } from './http';
import { decodePermissions } from '@/common/jwt-decode';
import { getPrestadorToken, getAutoagendamentoToken } from '@/common/storage';
import router from '../router';

export async function login(username, password) {
    const client = getClientBase();
    const { data } = await client.post('/auth/login', {
        username,
        password
    });

    if (data.access_token) {
        storage.clear();
        storage.setToken(data.access_token);

        const profile = await getProfile();

        storage.setTenant(profile.defaultCompany?.apiKey?.key);
        storage.setDefaultCompanyId(profile.defaultCompanyId);
        storage.setProfile(profile);
        storage.setCompany(profile.defaultCompany);

        const permissions = decodePermissions(data.access_token);
        store.dispatch('addToPermissions', permissions);
    }
}

export async function loginPrestador(cnpj, senha, cadastroPrestador = false) {
    const client = getClientBase();
    const { data } = await client.post(`/auth/${cadastroPrestador ? 'loginCadastroPrestador' : 'loginPrestador'}`, {
        username: cnpj,
        password: senha
    });

    if (data.access_token) {
        storage.clear();
        storage.setPrestadorToken(data.access_token);
    }
}

export async function loginAutoagendamento(cpf, token, termoAceito) {
    const client = getClientBase();
    const { data } = await client.post('/auth/login-autoagendamento', {
        username: cpf,
        password: token,
        termoAceito
    });

    if (data.access_token) {
        storage.clear();
        storage.setAutoagendamentoToken(data.access_token);
    }
}

export async function saveProfile(profile) {
    const { data } = await getClientBase().put('/@me', profile);
    return data;
}

export async function updatePassword(passwords) {
    const { data } = await getClientBase().put('/@me/password', passwords);
    return data;
}

export async function getProfile() {
    const path = '/auth/me';
    const { data } = await getClientBase().get(path);
    return data;
}

export async function getCompany() {
    const path = '/companies/find/me';
    const { data } = await getClientBase().get(path);
    return data;
}

export function logout(callback) {
    storage.deleteToken();
    storage.deleteTenant();
    storage.deletePermissions();
    storage.deleteProfile();

    if (callback) {
        callback();
    } else {
        router.push({ path: '/login', query: { redirect: router.currentRoute.fullPath, tokenExpirado: 'true' } });
    }
}

export function logoutPrestador(callback) {
    storage.deletePrestadorToken();

    if (callback) {
        callback();
    }
}

export function logoutAutoagendamento() {
    storage.deleteAutoagendamentoToken();

    router.push('/autoagendamento/login');
}

export function isLogged() {
    return !!storage.getToken();
}

export function isPrestadorAutenticado() {
    return !!storage.getPrestadorToken();
}

export function isAutoagendamentoAutenticado() {
    return !!storage.getAutoagendamentoToken();
}

export async function nomeUsuarioReduzido(qtdCaracteres) {
    const usuario = await getProfile();
    return limitarCaracteres(usuario.name, qtdCaracteres);
}

export async function nomeEmpresaUsuarioReduzido(qtdCaracteres) {
    const usuario = await getProfile();
    return limitarCaracteres(usuario.defaultCompany.name, qtdCaracteres);
}

export function limitarCaracteres(nome, qtdCaracteres) {
    if (nome.length >= qtdCaracteres) {
        return nome.substring(0, qtdCaracteres - 3) + '...';
    }
    return nome;
}

export function getIdsAuthorizedCustomers() {
    const profile = storage.getProfile();
    const customersIds = profile?.userCustomers?.map((c) => c.customerId);
    return customersIds;
}

export function getIdsAuthorizedCustomersBranches() {
    const profile = storage.getProfile();
    const customersBranchesIds = profile?.userCustomerBranches?.map((c) => c.customerBranchId);
    return customersBranchesIds;
}

export function tratarTokenPrestador() {
    const token = getPrestadorToken();
    const tokenDecodificado = decodificarToken(token);
    if (!tokenDecodificado || !tokenDecodificado.exp) {
        return;
    }

    const tempoAtualEmSegundos = Date.now() / 1000;
    if (tempoAtualEmSegundos > tokenDecodificado.exp) {
        logoutPrestador();
    }
}

export function tratarTokenAutoagendamento() {
    const token = getAutoagendamentoToken();
    const tokenDecodificado = decodificarToken(token);
    if (!tokenDecodificado || !tokenDecodificado.exp) {
        return;
    }

    const tempoAtualEmSegundos = Date.now() / 1000;
    if (tempoAtualEmSegundos > tokenDecodificado.exp) {
        logoutAutoagendamento();
    }
}

function decodificarToken(token) {
    try {
        return JSON.parse(window.atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}
